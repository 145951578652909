// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import apolloClient from '../apolloClient';

import DOMAIN_ALIAS_SETTINGS_PAGE_QUERY from 'queries/DomainAliasSettingsPage.graphql';
import HOSTING_SETTINGS_PAGE from 'queries/HostingSettingsPage.graphql';

export default [
    {
        path: 'hosting-settings',
        componentLoader: () => import(/* webpackChunkName: "components/pages/HostingSettingsPage" */'components/pages/HostingSettingsPage'),
        loader: async ({ params }) => {
            await apolloClient.query({
                query: HOSTING_SETTINGS_PAGE,
                variables: { id: btoa(`Domain:${params.id}`) },
            });
        },
    },
    {
        path: 'domain-alias-settings',
        componentLoader: () => import(/* webpackChunkName: "components/pages/DomainAliasSettingsPage" */'components/pages/DomainAliasSettingsPage'),
        loader: async ({ params }) => {
            await apolloClient.query({
                query: DOMAIN_ALIAS_SETTINGS_PAGE_QUERY,
                variables: { id: btoa(`DomainAlias:${params.id}`) },
            });
        },
    },
];
